import styled from 'styled-components';
import theme from 'styled-theming';
import { DEFAULT_THEME } from '../../config';

const color = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_color_dark_blue,
});

const border = theme('theme', {
  [DEFAULT_THEME]: props => props.type === 'light' ? `.13em solid ${props?.theme?.palette?.main_color_dark_blue}` : 'none',
});

const backgroundColor = theme('theme', {
  [DEFAULT_THEME]: props => props.type === 'light' ? props?.theme?.palette?.main_color_white : props?.theme?.palette?.main_color_orange,
});

export const ButtonStyled = styled.button`
  color: ${color};
  padding: .5em 1em;
  background-color: ${backgroundColor};
  border: ${border};
  border-radius: .2em;
  font-size: 1em;
  line-height: 1.25em;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  text-align: center;

  &:hover {
    opacity: .8;
  }

  &:focus {
    outline: none;
  }

  &:active {
    box-shadow: ${props => `0 0 0.25em 0.095em ${props?.theme?.palette?.main_color_shadow_grey}`};
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`;
