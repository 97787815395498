import styled from 'styled-components';
import theme from 'styled-theming';
import { MIN_WIDTH_MD, DEFAULT_THEME } from '../../config';

const border = theme('theme', {
  [DEFAULT_THEME]: _ => `.1em solid rgba(0, 0, 0, 0.1)`,
});

const borderRadius = theme('theme', {
  [DEFAULT_THEME]: '.125em',
});

const placeholderColor = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_color_dark_blue,
});

const boxShadow = theme('theme', {
  [DEFAULT_THEME]: props => `0 0 0.25em 0.02em ${props?.theme?.palette?.main_color_shadow_grey}`,
});

export const InputStyled = styled.input`
  box-sizing: border-box;
  padding: .375em .5em;
  margin: .1em;
  color: ${props => props?.theme?.palette?.main_color_dark_blue};
  background-color: ${props => props?.theme?.palette?.main_color_placeholder_grey};
  border: ${border};
  border-radius: ${borderRadius};
  font-weight: 700;
  font-size: 1em;
  line-height: normal;
  appearance: none;
  text-align: center;

  &::placeholder {
    color: ${placeholderColor};
    opacity: .3;
  }

  &:focus {
    outline: none;
    box-shadow: ${boxShadow};
    ::placeholder {
      color: rgba(165, 173, 216, 0.3);
    }
  }

  &:disabled {
    opacity: .8;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    padding: .5em .9em;
    font-size: 1.1em;
  }
`;
