import styled from 'styled-components';
import { MIN_WIDTH_MD } from '../../config';

export const ErrorPageTitleStyled = styled.title`
  height: 4em;
  margin: 0 .5em;
  display: flex;

  > h2 {
    font-size: 1.4em;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    > h2 {
      font-size: 1.5em;
    }
  }
`;

export const ErroPageContentStyled = styled.div``;
export const ErrorPageStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  margin: 0 1em;
  
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    padding: .5em;
    margin-top: 1em;
  }
`;

