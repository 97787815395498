import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from '../../components';
import { imgLinks, FAILURE_GREY } from '../../config';
import { ParagraphCenteredStyled, ParagraphHeadingStyled, ParagraphStyled } from '../styled/CommonComponents.styled';
import { ErrorPageStyled, ErroPageContentStyled, ErrorPageTitleStyled } from '../styled/ErrorPage.styled';

export const ErrorPage = () => {
  const phoneNumber = '030-8145862-90';
  const supportEmail = 'support@petolo.de';

  return (
    <ErrorPageStyled>
      <ErrorPageTitleStyled>
        <h2>
          <FormattedMessage id='page.error.title' />
        </h2>
      </ErrorPageTitleStyled>
      <Card>
        <ErroPageContentStyled>
          <ParagraphCenteredStyled>
            <img src={imgLinks[FAILURE_GREY]} alt="petolo" />
          </ParagraphCenteredStyled>
          <ParagraphCenteredStyled>
            <h2>
              <FormattedMessage id='page.error.subtitle' />
            </h2>
          </ParagraphCenteredStyled>
          <ParagraphCenteredStyled>
            <FormattedMessage id='page.error.subtitle.explanation' />
          </ParagraphCenteredStyled>
          <ParagraphCenteredStyled>
            <ParagraphHeadingStyled>
              <FormattedMessage id='page.error.customer.service' />
            </ParagraphHeadingStyled>
            <FormattedMessage id='page.error.customer.service.description' />
          </ParagraphCenteredStyled>
          <ParagraphStyled>
            <FormattedMessage id='general.page.caption.phone' />: <span><a href="tel:030-8145862-90">{phoneNumber}</a></span>
          </ParagraphStyled>
          <ParagraphStyled>
            <FormattedMessage id='general.page.caption.email' />: <span><a href="mailto: support@petolo.de">{supportEmail}</a></span>
          </ParagraphStyled>
        </ErroPageContentStyled>
      </Card>
    </ErrorPageStyled>
  );
};
