import React from 'react';
import { Card } from '../../components';
import { ConfirmationCardContainerStyled, PageItemStyled, PageItemTitleStyled } from '../styled/ConfirmationPage.styled';

export const BasePageItem = ({ title, children }) => {
  return (
    <PageItemStyled>
      <PageItemTitleStyled>
        <h2>{title}</h2>
      </PageItemTitleStyled>
      <Card>
        <ConfirmationCardContainerStyled>
          {children}
        </ConfirmationCardContainerStyled>
      </Card>
    </PageItemStyled>
  );
}
