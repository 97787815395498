import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { Router } from './Router';
import { palette, DEFAULT_THEME } from './config';
import locale from './i18n';

const App = _ => {
  const [lang] = useState('de');
  
  return (
    <ThemeProvider theme={{ theme: DEFAULT_THEME, palette }}>
      <IntlProvider locale={lang} messages={locale[lang]}>
        <Router />
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
