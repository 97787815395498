import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { imgLinks, PETOLO_CARD } from '../../config';
import { Button } from '../../components';
import { BasePageItem } from './BasePageItem';
import { CONFIRMED, DENIED, useSubmitCustomerRequest } from '../../utils';
import { ParagraphCenteredStyled, ParagraphStyled, ValueStyled } from '../styled/CommonComponents.styled';
import { PageItemButtonsContainerStyled } from '../styled/ConfirmationPage.styled';

export const ConfirmPageItem = ({ title, oldValues, newValues }) => {
  const hasOldValues = Array.isArray(oldValues) && !!oldValues.length;
  const hasNewValues = Array.isArray(newValues) && !!newValues.length;
  const history = useHistory();
  const { dispatch, isLoading, data, error } = useSubmitCustomerRequest();

  const onConfirm = _ => dispatch(CONFIRMED);
  const onDeny = _ => dispatch(DENIED);

  useEffect(() => {
    if (!isLoading && (error || data)) {
      if (error || data?.status !== 'success') {
        history.push('/error');
      } else {
        history.push(data.operation === CONFIRMED ? '/#accepted' : '/#declined');
      }
    }
  }, [isLoading, data, error, history])

  return (
    <BasePageItem title={title}>
      <img src={imgLinks[PETOLO_CARD]} alt='Petolo' />
      <ParagraphCenteredStyled>
        <h3>
          <FormattedMessage id='page.confirmation.subtitle' />
        </h3>
      </ParagraphCenteredStyled>
      <ParagraphCenteredStyled>
        <FormattedMessage id='page.confirmation.description' />
      </ParagraphCenteredStyled>
      {hasNewValues &&
        <>
          <ParagraphStyled>
            <FormattedMessage id='page.confirmation.change.suggested' />
          </ParagraphStyled>
          <ParagraphStyled>
            {newValues.map((value, i) => <ValueStyled key={i}>{value}</ValueStyled>)}
          </ParagraphStyled>
        </>
      }
      {hasOldValues &&
        <>
          <ParagraphStyled>
            <FormattedMessage id='page.confirmation.change.current' />
          </ParagraphStyled>
          <ParagraphStyled>
            {oldValues.map((value, i) => <ValueStyled key={i}>{value}</ValueStyled>)}
          </ParagraphStyled>
        </>
      }
      <PageItemButtonsContainerStyled>
        <Button
          caption={<FormattedMessage id='page.confirmation.button.confirm' />}
          type='primary'
          disabled={!hasOldValues || !hasNewValues}
          onClick={onConfirm}
        />
        <Button
          caption={<FormattedMessage id='page.confirmation.button.decline' />}
          type='light'
          disabled={!hasOldValues || !hasNewValues}
          onClick={onDeny}
        />
      </PageItemButtonsContainerStyled>
    </BasePageItem>
  );
}
