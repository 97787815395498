import React from 'react';
import { ButtonStyled } from './styled/Button.styled';

export const Button = ({ type, disabled, caption, onClick }) => {
  const handleClick = () => {
    if (onClick && !disabled) {
      onClick();
    }
  }

  return (
    <ButtonStyled type={type} disabled={disabled} onClick={handleClick}>
      {caption}
    </ButtonStyled>
  );
}
