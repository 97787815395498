import styled from 'styled-components';
import theme from 'styled-theming';
import { DEFAULT_THEME } from '../../config';

const backgroundColor = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_color_grey,
});

export const OverlayContainerStyled = styled.div``;
export const OverlayStyled = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${backgroundColor};
  opacity: .9;
  z-index: 999;
`;
