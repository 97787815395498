import React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from 'styled-theming';
import { DEFAULT_THEME } from '../config';

const color = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_color_orange,
});

const loader1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const loader2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1em, 0);
  }
`;

const loader3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const LoaderContainerStyled = styled.div`
  position: relative;
  width: 2.5em;
  height: 1em;
  margin: auto 0;
  top: -1.75em;

  & div {
    position: absolute;
    top: 2em;
    width: .5em;
    height: .5em;
    border-radius: 50%;
    background: ${color};
  }

  & div:nth-child(1) {
    left: .125em;
    animation: ${loader1} cubic-bezier(0, 1, 1, 0) 0.6s infinite;
  }

  & div:nth-child(2) {
    left: .125em;
    animation: ${loader2} cubic-bezier(0, 1, 1, 0) 0.6s infinite;
  }
    
  & div:nth-child(3) {
    left: 1em;
    animation: ${loader2} cubic-bezier(0, 1, 1, 0) 0.6s infinite;
  }
    
  & div:nth-child(4) {
    left: 1.75em;
    animation: ${loader3} cubic-bezier(0, 1, 1, 0) 0.6s infinite;
  }
`;

export const Loader = () => {
  return (
    <LoaderContainerStyled>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoaderContainerStyled>
  );
}
