import React from 'react';
import { FormattedMessage } from 'react-intl';
import { imgLinks, CHECK_ORANGE } from '../../config';
import { BasePageItem } from './BasePageItem';
import { ParagraphCenteredStyled, ParagraphStyled, ValueStyled } from '../styled/CommonComponents.styled';

export const AcceptedPageItem = ({ newValues }) => {
  const hasNewValues = Array.isArray(newValues) && !!newValues.length;
  const templateValues = {
    accountLink: <a href="https://account.petolo.de">hier</a>
  };

  return (
    <BasePageItem>
      <ParagraphCenteredStyled>
        <img src={imgLinks[CHECK_ORANGE]} alt='Success' />
      </ParagraphCenteredStyled>
      <ParagraphCenteredStyled>
        <h2>
          <FormattedMessage id='page.confirmation.change.finished' />
        </h2>
      </ParagraphCenteredStyled>
      <ParagraphCenteredStyled>
        <FormattedMessage id='page.confirmation.change.description' values={templateValues}/>
      </ParagraphCenteredStyled>
      {hasNewValues &&
        <>
          <ParagraphStyled>
            <FormattedMessage id='page.confirmation.change.actual.data' />
          </ParagraphStyled>
          <ParagraphStyled>
            {newValues.map((value, i) => <ValueStyled key={i}>{value}</ValueStyled>)}
          </ParagraphStyled>
        </>
      }
      <ParagraphCenteredStyled>
        <FormattedMessage id='page.confirmation.change.help' />
      </ParagraphCenteredStyled>
      <ParagraphStyled>
        <FormattedMessage id='page.confirmation.change.team' />
      </ParagraphStyled>
    </BasePageItem>
  );
}
