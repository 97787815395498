import React, { useState, createContext } from 'react';
import { Loader } from '../components';
import { OverlayContainerStyled, OverlayStyled } from './styled/Overlay.styled';

export const OverlayContext = createContext({
  isOverlayShown: false,
  setIsOverlayShown: () => { },
});
export const Overlay = ({ children }) => {
  const [isOverlayShown, setIsOverlayShown] = useState(false);
  const value = { isOverlayShown, setIsOverlayShown };

  return (
    <OverlayContext.Provider value={value}>
       <OverlayContainerStyled>
        {isOverlayShown &&
          <OverlayStyled>
            <Loader/>
          </OverlayStyled>
        }
        {children}
      </OverlayContainerStyled>
    </OverlayContext.Provider>
  );
}
