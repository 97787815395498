import styled from 'styled-components';
import theme from 'styled-theming';
import { Link } from 'react-router-dom'
import { DEFAULT_THEME, MIN_WIDTH_MD } from '../../config';

const backgroundColor = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_color_yellow,
});

export const FooterStyled = styled.footer`
  min-height: 5em;
  display: flex;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  background: ${backgroundColor};
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const FooterTrademarkStyled = styled.section`
  color: ${props => props?.theme?.palette?.main_color_orange};
  margin: 1em 0;
  font-size: .8em;
  font-weight: 600;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin: 0;
    font-size: 1em;
  }
`;

export const FooterLinksStyled = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  font-weight: 600;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;
  }
`;

export const FooterLinkStyled = styled.li`
  list-style-type: none;
  font-size: .8em;
  margin: .5em 0;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1em;
    margin: 0;
  }
`;

export const FooterItemsStyled = styled.nav`
  margin: 0 5%;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin: 0 15%;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${props => props?.theme?.palette?.main_color_dark_blue};
  margin-right: .7em;

  &:hover {
    text-decoration: underline;
  }
`;
