import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { imgLinks, PETOLO_LOGO_BLUE } from '../config';
import { HeaderStyled, HeaderItemsStyled } from './styled/Header.styled';

const Header = () => {
  return (
    <HeaderStyled>
      <HeaderItemsStyled>
        <Link to='/'>
          <img src={imgLinks[PETOLO_LOGO_BLUE]} alt="Petolo" />
        </Link>
      </HeaderItemsStyled>
    </HeaderStyled>
  );
}

export default injectIntl(Header);
