import styled from 'styled-components';
import { MIN_WIDTH_MD } from '../../config';

export const ParagraphCenteredStyled = styled.div`
  text-align: center;
  font-weight: 600;
  margin: 1em;
  line-height: 1.75em;

  img {
    width: 4em;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    img {
      width: 6em;
    }
  }
`;

export const ParagraphHeadingStyled = styled.h3`
  margin-bottom: .5em;
`;

export const ParagraphStyled = styled.p`
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    >h1, h2, h3 {
      margin: 0;
    }
  }
`;

export const ValueStyled = styled.div`
  margin-bottom: 8px;
  padding: 10px 20px;
  font-weight: bold;
  text-align: center;
  background: #fcfcfc;
  border: 1px solid rgba(0,0,0,0.1);

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    min-width: 24em;
  }
`;
