import styled from 'styled-components';
import theme from 'styled-theming';
import { MIN_WIDTH_MD, DEFAULT_THEME } from '../../config';

const backgroundColor = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_color_white,
});

const padding = theme('theme', {
  [DEFAULT_THEME]: '.7em',
});

const paddingDesktop = theme('theme', {
  [DEFAULT_THEME]: '1em 3em',
});

export const CardStyled = styled.article`
  display: flex;
  justify-content: center;
  padding: ${padding};
  background: ${backgroundColor};
  border: ${props => props?.theme?.palette?.card_border};
  box-shadow: ${props => props?.theme?.palette?.card_box_shadow};
  
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    padding: ${paddingDesktop};
  }
`;
