import styled from 'styled-components';
import { MIN_WIDTH_MD } from '../../config';

export const ConfirmationPageStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ConfirmationCardContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 35em;
`;

export const PageItemStyled = styled.div`
  width: 85%;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    width: auto;
  }
`;

export const PageItemTitleStyled = styled.title`
  height: 4em;
  margin: 0 .5em;
  display: flex;
  align-items: center;

  > h2 {
    font-size: 1.4em;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    > h2 {
      font-size: 1.5em;
    }
  }
`;

export const PageItemButtonsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0;

  >button {
    width: 100%;
    margin-top: .5em;
  }
  
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    padding: .5em;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1em;

    >button {
      width: 40%;
    }
  }
`;
