import styled from 'styled-components';
import theme from 'styled-theming';
import { MIN_WIDTH_MD, DEFAULT_THEME } from '../../config';

const backgroundColor = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_color_grey,
});

export const MainStyled = styled.main`
  display: flex;
  align-items: center;
  padding-top: 4em;
  padding-bottom: 8em;
  background: ${backgroundColor};
  margin-left: 0;
  margin-right: 0;
  
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin-left: 15%;
    margin-right: 15%;
  }
`;
