import {apiUrls} from '../utils/helpers'
export const TRADEMARK_TEXT = 'petolo ist eine Marke der © dentolo Deutschland GmbH';

export const getIcon = icon => `//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/icons/${icon}.svg`;
export const getUserAreaIcon = icon => `//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/user-area-icons/${icon}.svg`;
export const getLogoIcon = icon => `//s3.eu-central-1.amazonaws.com/dentolo-ui-elements/dist/img/logos/${icon}.svg`;

export const PETOLO_LOGO_BLUE = 'icon.petolo.logo.blue';
export const PETOLO_CARD = 'icon.petolo.card';
export const CHECK_ORANGE = 'icon.check.circle';
export const FAILURE_GREY = 'icon.failure.grey';

export const imgLinks = {
  [PETOLO_LOGO_BLUE]: getUserAreaIcon('petolo/icon-petolo-logo-blue'),
  [PETOLO_CARD]: getUserAreaIcon('petolo/icon-petolo-card'),
  [CHECK_ORANGE]: getUserAreaIcon('petolo/icon-checked-filled-border-orange'),
  [FAILURE_GREY]: getUserAreaIcon('icon.failure'),
};

export const FAIRCARE_BASE_URL = apiUrls();
export const KEY_ID = process.env.REACT_APP_KEY_ID
