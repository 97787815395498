import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { Header, Footer, Main, Overlay } from './layout';
import { ConfirmationPage } from './pages';
import { ErrorPage } from './pages';

const ContentsWrapperStyled = styled.div`
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const routes = [
  { path: '/', name: 'Confirmation', component: ConfirmationPage },
  { path: '/error', name: 'Error', component: ErrorPage },
];

export const HashRoute = withRouter(({ component: Component, hash, ...routeProps }) => {
  const pageItemRef = useRef(null);
  return <Route {...routeProps}>
    {({ location }) =>
      <CSSTransition nodeRef={pageItemRef}
        in={location.hash === hash}
        timeout={500}
        classNames='page-item'
        unmountOnExit
      >
        <ContentsWrapperStyled ref={pageItemRef} className='page-item'>
          <Component />
        </ContentsWrapperStyled>
      </CSSTransition>}
  </Route>
});

const ScrollToTop = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);

  return (null);
});

export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Overlay>
          <Header />
          <Main>
            {routes.map(route => <Route exact key={route.name} path={route.path} component={route.component} />)}
          </Main>
          <Footer />
        </Overlay>
      </Switch>
    </BrowserRouter>
  );
};
