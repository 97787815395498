import React, { useEffect, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { HashRoute } from '../../Router';
import { ConfirmPageItem } from './ConfirmPageItem';
import { AcceptedPageItem } from './AcceptedPageItem';
import { DeclinedPageItem } from './DeclinedPageItem';
import { OverlayContext } from '../../layout';
import { ConfirmationPageStyled } from '../styled/ConfirmationPage.styled';
import { useRequestCustomerData, parseCustomerData, getOldValues, getNewValues } from '../../utils';

const HASH_ACCEPTED = '#accepted';
const HASH_DECLINED = '#declined';
const routes = [
  { path: '', component: (oldValues, newValues) => _ => <ConfirmPageItem oldValues={oldValues} newValues={newValues} title={<FormattedMessage id='page.confirmation.title' />} /> },
  { path: HASH_ACCEPTED, component: (oldValues, newValues) => _ => <AcceptedPageItem oldValues={oldValues} newValues={newValues} /> },
  { path: HASH_DECLINED, component: (oldValues, newValues) => _ => <DeclinedPageItem oldValues={oldValues} newValues={newValues} /> },
];

export const ConfirmationPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [oldValues, setOldValues] = useState([]);
  const [newValues, setNewValues] = useState([]);
  const { setIsOverlayShown } = useContext(OverlayContext);
  const { dispatch, isLoading, data, error } = useRequestCustomerData();
  const isBasePage = [HASH_ACCEPTED, HASH_DECLINED].indexOf(location?.hash) === -1;

  useEffect(() => {
    const { customer_data_changes } = data || {};

    if (isBasePage) {
      dispatch();
      setIsOverlayShown(true);
      
      if (!isLoading) {
        setIsOverlayShown(false);
        if (error || !customer_data_changes) {
          history.push('/error');
        } else {
          const customerChanges = parseCustomerData(customer_data_changes);
          setOldValues(getOldValues(customerChanges));
          setNewValues(getNewValues(customerChanges))
        }
      }
    }
  }, [dispatch, isBasePage, isLoading, error, data, history, setIsOverlayShown])

  return (
    <ConfirmationPageStyled>
      {routes.map(route => <HashRoute key={route.path} hash={route.path} component={route.component(oldValues, newValues)} />)}
    </ConfirmationPageStyled>
  );
}
