import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { FooterStyled, FooterLinksStyled, FooterTrademarkStyled,
  FooterLinkStyled, FooterItemsStyled, LinkStyled } from './styled/Footer.styled';
import { TRADEMARK_TEXT } from '../config';

const footerLinks = [
  {
    id: 'initial',
    title: <FormattedMessage id='general.footer.info.initial' />,
    path: '#',
    onClick: _ => { },
  },
  {
    id: 'imprint',
    title: <FormattedMessage id='general.footer.info.imprint' />,
    path: 'https://www.petolo.de/impressum',
    onClick: _ => { },
  },
  {
    id: 'privacy',
    title: <FormattedMessage id='general.footer.info.privacy' />,
    path: 'https://www.petolo.de/datenschutz',
    onClick: _ => { },
  },
]

const Footer = () => {
  return (
    <FooterStyled>
      <FooterItemsStyled>
        <FooterLinksStyled>
          {footerLinks.map(link =>
            <FooterLinkStyled key={link.id}>
              <LinkStyled target='_blank' to={{ pathname: link.path }}>{link.title}</LinkStyled>
            </FooterLinkStyled>
          )}
        </FooterLinksStyled>
        <FooterTrademarkStyled>
          {TRADEMARK_TEXT}
        </FooterTrademarkStyled>
      </FooterItemsStyled>
    </FooterStyled>
  );
}

export default injectIntl(Footer);
