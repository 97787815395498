import styled from 'styled-components';
import theme from 'styled-theming';
import { MIN_WIDTH_MD, DEFAULT_THEME } from '../../config';

const backgroundColor = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_color_white,
});

const boxShadow = theme('theme', {
  [DEFAULT_THEME]: props => props?.theme?.palette?.main_header_box_shadow,
});

export const HeaderStyled = styled.header`
  height: 3.5em;
  display: flex;
  align-items: center;
  padding: .4em;
  box-sizing: border-box;
  background: ${backgroundColor};
  box-shadow: ${boxShadow};
  margin-bottom: .5em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
`;

export const HeaderItemsStyled = styled.section`
  margin: 0 5%;
  width: 100%;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin: 0 15%;
  }
`;
