import { useState, useEffect, useCallback } from 'react';
import { FAIRCARE_BASE_URL, KEY_ID } from '../config';
import { getUrlParams, fetchData } from './helpers';

export const REQUEST_ID_PARAM = 'request_id';
export const CONFIRMED = 'confirmed';
export const DENIED = 'denied';

export const getRequestUrl = id => `${FAIRCARE_BASE_URL}/v1/customer_update_requests/${id || ''}`;
export const getRequestBaseOptions = _ => ({
  headers: {
    'Content-type': 'application/json',
    'X-API-KEY': KEY_ID,
    KeyId: KEY_ID,
    mode: 'no-cors',
  },
});

export const useFetch = (url, options = {}, dispatched) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (dispatched) {
      const request = fetchData(url, options);
      request(setResponse, setError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatched, url]);

  return { response, error };
};

export const useRequestCustomerData = () => {
  const [loading, setLoading] = useState(true);
  const [dispatched, setDispatched] = useState(false);
  const dispatch = useCallback(_ => setDispatched(true), []);
  const requestId = getUrlParams(REQUEST_ID_PARAM, window.location.href);
  const options = getRequestBaseOptions();
  const url = getRequestUrl(requestId);
  const { response, error } = useFetch(url, options, dispatched);
  
  useEffect(() => {
    if (response || error) {
      setDispatched(false)
      setLoading(false);
    }
  }, [response, error]);

  return { dispatch, isLoading: loading, data: response, error };
};

export const useSubmitCustomerRequest = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [operation, setOperation] = useState(null);
  const dispatch = useCallback(status => setOperation(status), []);

  useEffect(() => {
    if (data || error) {
      setLoading(false);
      setOperation(null);
    }
  }, [data, error])

  useEffect(() => {
    const requestId = getUrlParams(REQUEST_ID_PARAM, window.location.href);
    const payload = {
      request_status: operation,
    };
    const options = {
      ...getRequestBaseOptions(),
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    
    const url = getRequestUrl(requestId);
    const request = fetchData(url, options);
    if (operation) {
      setLoading(true);
      request(
        response => setData({ ...response, operation }),
        error => setError(error));
    }
  }, [operation]);

  return { dispatch, isLoading: loading, data, error };
};
