import React from 'react';
import { injectIntl } from 'react-intl';
import { MainStyled } from './styled/Main.styled';

const Main = ({ children }) => {
  return (
    <MainStyled>
      {children}
    </MainStyled>
  );
}

export default injectIntl(Main);
