export const fetchData = (url, options) => async (onSuccess, onError) => {
  try {
    const res = await fetch(url, options);
    const json = await res.json();
    onSuccess(json);
  } catch (error) {
    onError(error);
  }
}

export const getUrlParams = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  let results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getAllUrlParams = url => {
  var params = {};
  var parser = document.createElement('a');
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

export const EMAIL_PROP = 'email';
export const PHONE_PROP = 'phone_number';
export const ADDRESS_PROP = 'address';
export const CHANGE_PROPERTIES = [EMAIL_PROP, PHONE_PROP, ADDRESS_PROP];
export const parseCustomerData = customerChanges => {
  let changes = {};

  if (Array.isArray(customerChanges)) {
    changes = CHANGE_PROPERTIES.reduce((changedProps, propName) => {
      const change = customerChanges.find(item => item?.in === propName);
      if (change) {
        changedProps[propName] = {...change?.requested_changes};
      }
      return changedProps;
    }, {});
  }

  return changes;
}
export const getOldValues = changes => Object.keys(changes).map(key => changes[key]?.old_value);
export const getNewValues = changes => Object.keys(changes).map(key => changes[key]?.updated_value);



export const getEnvironment = () => {
  let environment;
  // check if localhost
  const localhost = window.location.href.indexOf('localhost');
  // check if beta
  const beta = window.location.href.indexOf('verify-staging.petolo');

  if (localhost > 0) {
    environment = 'localhost';
  } else if (beta > 0) {
    environment = 'beta';
  } else {
    environment = 'production';
  }
  return environment;
};

const getBetaVersion=()=>{
  let url_string = window.location
  let url = new URL(url_string);
  let betaVersion = url.searchParams.get("beta_env");
  return betaVersion?betaVersion:'beta15'
}

export const apiUrls = () => {
  const env = getEnvironment();
  if (env === 'localhost') {
    return 'http://localhost:3000/api';
  } else if (env === 'beta') {
    const betaVersion=getBetaVersion()
    return `//${betaVersion}.dentolo-test.de/api`;

  } else {
      return '//api.zurich.de/faircare/api';
  }

};

