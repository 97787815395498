export const DEFAULT_THEME = 'default';

export const MAX_WIDTH_XS = '480px';
export const MIN_WIDTH_SM = '481px';
export const MAX_WIDTH_SM = '768px';
export const MIN_WIDTH_MD = '769px';

export const palette = {
  // colors
  main_color_orange: '#FFA445',
  main_color_white: '#FFFFFF',
  main_color_yellow: '#FFF4D9',
  main_color_dark_blue: '#003264',
  main_color_grey: '#F7F7F7',
  main_color_placeholder_grey: '#FCFCFC',
  main_color_shadow_grey: '#DEDEDE',
  main_color_black: '#000000',
  // border
  main_header_box_shadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
  card_box_shadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  card_border: '1px solid rgba(0, 0, 0, 0.1)',
  // font
  main_font_family: 'Roboto',
};
