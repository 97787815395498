import React from 'react';
import { CardStyled } from './styled/Card.styled';

export const Card = ({ children }) => {
  return (
    <CardStyled>
      {children}
    </CardStyled>
  );
}
